import * as Yup from 'yup';
import {MortgageDto} from '@finanso/api-common';
import {getRealtyAmount} from './utils';
import {AMOUNT_MAX_VALUE, AMOUNT_MIN_VALUE, DEFAULT_LTV_RATIO, REALTY_MAX_VALUE, REALTY_MIN_VALUE} from '../../constants';
import {formatCurrencyWithNbps} from '../Currency';

export const initData = {
    realtyAmount: 4_000_000,
    amount: 2_000_000,
    term: 25,
    fixation: 'FIX_05',
    insurance: true,
};
export const getInitData = (mortgage?: MortgageDto): MortgageFormDataProps => {
    return {
        realtyAmount: getRealtyAmount(mortgage) || initData.realtyAmount,
        hideRealtyAmount: !!mortgage?.finalRealtyValue,
        amount: mortgage?.amountMortgage || mortgage?.amountTotal || initData.amount,
        term: mortgage?.term || initData.term,
        fixation: mortgage?.cbFixation?.code || initData.fixation,
        insurance: typeof mortgage?.insurance === 'boolean' ? mortgage.insurance : initData.insurance,
    };
};

export type MortgageFormDataProps = typeof initData & {hideRealtyAmount?: boolean};

// Do not use `value` inside .test() inside this schema, it cannot be trusted due to this dynamic approach to modifying the values. Use `this.parent.FIELD` instead.
// While using this schema with formik, you have to includ the <DebounceOnChangeFormikEffect/> with onChange which validates the schema.
//  Otherwise the form will non-stop correct the user input, making the fields unusable.
//  Validation at the schema level was specifically selected because of it's wide usage within the codebase.
export const getMortgageFormSchema = (
    StringRequiredSchema: Yup.StringSchema<string, object>,
    BooleanRequiredSchema: Yup.BooleanSchema<boolean, object>,
    PositiveNumberRequiredSchema: Yup.NumberSchema<number, object>
) =>
    Yup.object<MortgageFormDataProps>({
        fixation: StringRequiredSchema,
        insurance: BooleanRequiredSchema,
        term: PositiveNumberRequiredSchema.min(5, 'Doba splácení musí být větší než 5 let').max(30, 'Doba splácení musí být menší než 30 let'),
        realtyAmount: PositiveNumberRequiredSchema.test({
            name: 'ltv-check-realtyAmount',
            exclusive: false,
            test: function (this) {
                // realtyAmount change triggers this condition
                if (this.parent._OLD_realtyAmount !== this.parent.realtyAmount) {
                    const lowestValueAvailableForLoan = Math.floor(this.parent.realtyAmount * DEFAULT_LTV_RATIO);
                    if (
                        this.parent.amount > lowestValueAvailableForLoan ||
                        this.parent._OLD_amount === AMOUNT_MIN_VALUE ||
                        this.parent._OLD_amount === 0
                    ) {
                        this.parent.amount = lowestValueAvailableForLoan;
                        this.parent._OLD_amount = lowestValueAvailableForLoan;
                    }
                    if (this.parent.amount < AMOUNT_MIN_VALUE) {
                        this.parent.amount = AMOUNT_MIN_VALUE;
                        this.parent._OLD_amount = AMOUNT_MIN_VALUE;
                    }
                }
                this.parent._OLD_realtyAmount = this.parent.realtyAmount;

                // amount change triggers this condition
                if (this.parent._OLD_amount !== this.parent.amount) {
                    let lowestPossibleRealtyValue = Math.ceil(this.parent.amount / DEFAULT_LTV_RATIO);
                    if (lowestPossibleRealtyValue < REALTY_MIN_VALUE) {
                        lowestPossibleRealtyValue = REALTY_MIN_VALUE;
                    }
                    if (this.parent.realtyAmount < lowestPossibleRealtyValue) {
                        this.parent.realtyAmount = lowestPossibleRealtyValue;
                        this.parent._OLD_realtyAmount = lowestPossibleRealtyValue;
                    }
                    if (this.parent.realtyAmount > REALTY_MAX_VALUE) {
                        this.parent.realtyAmount = REALTY_MAX_VALUE;
                        this.parent._OLD_realtyAmount = REALTY_MAX_VALUE;
                    }
                }
                this.parent._OLD_amount = this.parent.amount;

                return true;
            },
        })
            // handle MIN and MAX values manually to prevent auto-submision due to async validation -> changes to unmounted components
            .test({
                name: 'ltv-check-realtyAmount-min-max',
                exclusive: false,
                test: function (this, _) {
                    if (this.parent.realtyAmount < REALTY_MIN_VALUE) {
                        return this.createError({
                            message: `Výše deklarované hodnoty nemovitosti nesmí být menší než ${formatCurrencyWithNbps({
                                value: REALTY_MIN_VALUE,
                            })}`,
                        });
                    }
                    if (this.parent.realtyAmount > REALTY_MAX_VALUE) {
                        return this.createError({
                            message: `Výše deklarované hodnoty nemovitosti nesmí být vyšší než ${formatCurrencyWithNbps({
                                value: REALTY_MAX_VALUE,
                            })}`,
                        });
                    }
                    return true;
                },
            })
            .test({
                name: 'ltv-check-amout-min-max',
                exclusive: false,
                test: function (this, _) {
                    if (this.parent.amount < AMOUNT_MIN_VALUE) {
                        return this.createError({
                            path: 'amount',
                            message: `Výše úvěru nesmí být menší než ${formatCurrencyWithNbps({
                                value: AMOUNT_MIN_VALUE,
                            })}`,
                        });
                    }
                    if (this.parent.amount > AMOUNT_MAX_VALUE) {
                        return this.createError({
                            path: 'amount',
                            message: `Výše úvěru nesmí být vyšší než ${formatCurrencyWithNbps({
                                value: AMOUNT_MAX_VALUE,
                            })}`,
                        });
                    }
                    return true;
                },
            }),
        amount: PositiveNumberRequiredSchema,
    });
